import React, { useState, useContext, createContext } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  const [itemCount, setItemCount] = useState({});
  const [cartTotal, setCartTotal] = useState(0);

  const addToCart = (item, value, size) => {
    const new_item = { ...item, size };
    const new_items = Array.from({ length: value }, () => new_item);
    setItems((prevItems) => [...prevItems, ...new_items]);
    setItemCount((prevItemCount) => ({
      ...prevItemCount,
      [`${new_item.name} ${new_item.size}`]: (parseInt(prevItemCount[`${new_item.name} ${new_item.size}`]) || 0) + parseInt(value)
,    }));
    setCartTotal((prevCartTotal) => prevCartTotal + (item.price * value));
  };

  const changeCount = (item, count) => {
    setItemCount({ ...itemCount, [`${item.name} ${item.size}`]: count });
  };

  const removeFromCart = (name, price_id) => {
    setItems(items.filter((item) => item.name !== name));
    setItemCount({ ...itemCount, [price_id]: 0 });
  };

  return (
    <CartContext.Provider
      value={{
        items,
        itemCount,
        cartTotal,
        addToCart,
        removeFromCart,
        changeCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
