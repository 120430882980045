// import Swiper core and required modules
import React, { useState, useEffect, useRef } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import autoAnimate from "@formkit/auto-animate";
import Slide from "./Slide";
import Cart from "./Cart";

// Import Swiper styles
import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./Slideshow.css";

const Slideshow = () => {
  const [slideData, setSlideData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API}products`);
      const data = await response.json();
      const uniqueItems = [];
      const uniqueNames = new Set();

      data.forEach((item) => {
        if (!uniqueNames.has(item.name)) {
          uniqueNames.add(item.name);
          const newItem = { ...item };
          delete newItem._id;
          delete newItem.description;
          delete newItem.price_id;
          delete newItem.inventory;
          uniqueItems.push(newItem);
        }
      });
      setSlideData(uniqueItems);
    };
    fetchData();
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10);
  }, []);

  const parentRef = useRef(null);

  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current, { duration: 1500 });
    }
  }, []);

  const getSwiperDirection = () => {
    if (window.innerWidth <= 480) {
      return "vertical"; // Switch to vertical for screens less than or equal to 768px
    } else {
      return "horizontal"; // Use horizontal for larger screens
    }
  };
  const [swiperDirection, setSwiperDirection] = useState(getSwiperDirection());

  useEffect(() => {
    const handleResize = () => {
      setSwiperDirection(getSwiperDirection());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    

  return (
    <Swiper
      direction={swiperDirection}
      modules={[Navigation, Pagination, Scrollbar, A11y, Virtual]}
      height={window.innerHeight}
      spaceBetween={window.innerWidth > 480 ? window.innerHeight : 0}
      slidesPerView={1}
      navigation={swiperDirection === "vertical" ? false : true}
      loop={true}
      speed={1000}
      breakpoints={{
        800: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      }}
    >
      <SwiperSlide className="home-slide slide">
        <div className="home-slide-container" ref={parentRef}>
          {!loading && <h1 className="home-title">boop.</h1>}
        </div>
      </SwiperSlide>
      {slideData.map((slide, index) => {
        if (slide.inventory !== 0) {
          return (
            <SwiperSlide className="slide" key={index}>
              {({ isActive }) => (
                <Slide
                  slide={slide}
                  index={index}
                  isActive={isActive}
                  isVertical={swiperDirection}
                />
              )}
            </SwiperSlide>
          );
        }
        else {
          return null;
        }
      })}
      <SwiperSlide className="slide">
        <Cart />
      </SwiperSlide>
    </Swiper>
  );
};

export default Slideshow;