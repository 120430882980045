import React, { useState } from "react";
import { Link } from "react-router-dom"; // If you're using React Router for navigation
import styles from "./ResultPage.module.css";

const ResultPage = ({ title }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Define the style object for the default state
  const defaultStyle = {
    color: "white",
    textDecoration: "none",
  };

  // Define the style object for the hover state
  const hoverStyle = {
    ...defaultStyle, // Include default styles
    textDecoration: "underline", // Add underline on hover
  };

  const linkStyle = isHovered ? hoverStyle : defaultStyle;
  return (
    <div className={styles["bg"]}>
      <h1 className={styles["title"]}>{title}</h1>
      <Link
        to="/"
        style={linkStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        Return Home
      </Link>
    </div>
  );
};

const Cancel = () => {
  return <ResultPage title="Payment was canceled" />;
};

const Success = () => {
  return <ResultPage title="Payment was successful" />;
};

export { Cancel, Success };
