import React, { useEffect, useRef, useState } from "react";
import { useCart } from "./context/useCart";
import autoAnimate from "@formkit/auto-animate";
import { AiOutlineArrowRight } from "react-icons/ai";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./Slideshow.css";

const Slide = ({ slide, index, isActive, isVertical }) => {
  const [addSuccess, setAddSuccess] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [slideColor, setSlideColor] = useState();
  const [selectedSize, setSelectedSize] = useState("s");

  const elRef_1 = useRef(null);
  const elRef_2 = useRef(null);
  const elRef_3 = useRef(null);
  const elRef_4 = useRef(null);
  const elRef_5 = useRef(null);

  const { addToCart } = useCart();

  useEffect(() => {
    if (elRef_5.current) {
      autoAnimate(elRef_5.current, { duration: 1500 });
    }
  }, []);

  useEffect(() => {
    if (
      elRef_1.current &&
      elRef_2.current &&
      elRef_3.current &&
      elRef_4.current
    ) {
      autoAnimate(elRef_1.current, { duration: 1500 });
      autoAnimate(elRef_2.current, { duration: 1500 });
      autoAnimate(elRef_3.current, { duration: 1500 });
      autoAnimate(elRef_4.current, { duration: 1500 });
    }
  }, []);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };


  const handleAddToCart = () => {
    addToCart(slide, quantity, selectedSize);
    setAddSuccess(true);
    setTimeout(() => {
      setAddSuccess(false);
    }, 5000);
  };

  const handleInputChange = (e) => {
    if (Math.abs(e) === 1) {
      setQuantity(parseInt(e) + parseInt(quantity));
    } else {
      setQuantity(e.target.value);
    }
  };

  useEffect(() => {
    if (slide.name.includes("black")) {
      setSlideColor(true);
    } else {
      setSlideColor(false);
    }
  });

  return (
    <React.Fragment>
      {addSuccess && (
        <div
          className={addSuccess ? "success-popup" : "success-popup invisible"}
        >
          <button className="close-button" onClick={() => setAddSuccess(false)}>
            X
          </button>
          <h1 className="success-title">Item added successfully</h1>
        </div>
      )}

      <div className="slide-container">
        <div ref={elRef_1}>
          {isActive && <h1 className="slide-price">${slide.price}</h1>}
          {isVertical === "vertical" && (
            <h1 className="slide-price">${slide.price}</h1>
          )}
        </div>
        <div
          className={
            slideColor
              ? "slide-content-container"
              : "slide-content-container container-black"
          }
          ref={elRef_3}
        >
          {isActive && (
            <button
              className={
                !slideColor ? "slide-button" : "slide-button  button-black"
              }
              onClick={handleAddToCart}
            >
              Add to cart <AiOutlineArrowRight />
            </button>
          )}
          {isVertical === "vertical" && (
            <button
              className={
                !slideColor ? "slide-button" : "slide-button  button-black"
              }
              onClick={handleAddToCart}
            >
              Add to cart <AiOutlineArrowRight />
            </button>
          )}

          <div className="slide-img-container" ref={elRef_4}>
            {isActive && <img src={slide.image} className="slide-img" />}
            {isVertical === "vertical" && (
              <img src={slide.image} className="slide-img" />
            )}
            <form className="slide-form" ref={elRef_5}>
              {isActive && (
                <div className="input-container">
                  <div className="button-container-left">
                    {quantity > 1 && (
                      <button
                        type="button"
                        className={
                          slideColor
                            ? "decrease-button input-black"
                            : "decrease-button"
                        }
                        onClick={() => handleInputChange(-1)}
                      >
                        -
                      </button>
                    )}
                  </div>
                  <input
                    type="number"
                    min={1}
                    value={quantity}
                    className={
                      slideColor ? "slide-input input-black" : "slide-input"
                    }
                    onChange={handleInputChange}
                  />
                  <div className="button-container-right">
                    {quantity < 10 && (
                      <button
                        type="button"
                        className={
                          slideColor
                            ? "increase-button input-black"
                            : "increase-button"
                        }
                        onClick={() => handleInputChange(1)}
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              )}
              {isVertical === "vertical" && (
                <div className="input-container">
                  <div className="button-container-left">
                    {quantity > 1 && (
                      <button
                        type="button"
                        className={
                          slideColor
                            ? "decrease-button input-black"
                            : "decrease-button"
                        }
                        onClick={() => handleInputChange(-1)}
                      >
                        -
                      </button>
                    )}
                  </div>
                  <input
                    type="number"
                    min={1}
                    value={quantity}
                    className={
                      slideColor ? "slide-input input-black" : "slide-input"
                    }
                    onChange={handleInputChange}
                  />
                  <div className="button-container-right">
                    {quantity < 10 && (
                      <button
                        type="button"
                        className={
                          slideColor
                            ? "increase-button input-black"
                            : "increase-button"
                        }
                        onClick={() => handleInputChange(1)}
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              )}
              {/*radio buttons to choose sizes */}
              <div className="sizes">
                {slide.name.endsWith("tee.") && (
                  <div className="size-item">
                    <label className="size-label">xs</label>
                    <input
                      type="radio"
                      id="xs"
                      name="size"
                      value="xs"
                      onChange={handleSizeChange}
                      checked={selectedSize === "xs"}
                    />
                  </div>
                )}

                <div className="size-item">
                  <label className="size-label">s</label>
                  <input
                    type="radio"
                    id="s"
                    name="size"
                    value="s"
                    onChange={handleSizeChange}
                    checked={selectedSize === "s"}
                  />
                </div>

                <div className="size-item">
                  <label className="size-label">m</label>
                  <input
                    type="radio"
                    id="m"
                    name="size"
                    value="m"
                    onChange={handleSizeChange}
                    checked={selectedSize === "m"}
                  />
                </div>
                <div className="size-item">
                  <label className="size-label">l</label>
                  <input
                    type="radio"
                    id="l"
                    name="size"
                    value="l"
                    onChange={handleSizeChange}
                    checked={selectedSize === "l"}
                  />
                </div>
                {slide.name.endsWith("tee.") && (
                  <div className="size-item">
                    <label className="size-label">xl</label>
                    <input
                      type="radio"
                      id="xl"
                      name="size"
                      value="xl"
                      onChange={handleSizeChange}
                      checked={selectedSize === "xl"}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        <div ref={elRef_2}>
          {isActive && <h2 className="slide-title">{slide.name}</h2>}
          {isVertical === "vertical" && (
            <h2 className="slide-title">{slide.name}</h2>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Slide;
