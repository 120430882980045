import React from "react";
import Slideshow from "./Slideshow";
import { Cancel, Success } from "./ResultPage";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const BrowserRouter = createBrowserRouter([
  {
    path: "/",
    element: <Slideshow />,
  },
  {
    path: "/cancel",
    element: <Cancel />,
  },
  {
    path: "/success",
    element: <Success />,
  },
]);

function App() {
  return <RouterProvider router={BrowserRouter} />;
}

export default App;
