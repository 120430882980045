import React, { useEffect, useState } from "react";
import { useCart } from "./context/useCart";
import styles from "./Cart.module.css";

const Cart = () => {
  const { items, itemCount, removeFromCart, changeCount } = useCart();
  const [uniqueItems, setUniqueItems] = useState([]);
  const handleQuantityChange = (item, quantity) => {
    changeCount(item, quantity);
  };

  useEffect(() => {
    // Filter the items to keep only one item per unique title
    const unique = [];
    for (const key in itemCount) {
      const item_name = key.slice(0, -2);
      const item_size = key.split(" ")[3];
      // Check if the uniqueItems array already contains an object with the same name and size combination
      const isDuplicate = unique.some(item => item.name === item_name && item.size === item_size);
      // If not a duplicate, add the item and size to the uniqueItems array
      const foundItem = items.find(item => item.name === item_name)
      let price = null;
      if (foundItem) {
        price = foundItem.price;
      } else {
        price = 0;
      }
      if (!isDuplicate) {
        unique.push({ name: item_name, size: item_size, price: price });
      }
    }
    setUniqueItems(unique);
    // Now uniqueItems array contains only unique combinations of name and size
  }, [itemCount]);
  
  const handleCheckout = () => {
    fetch(`${process.env.REACT_APP_API}/create-checkout-session`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ listItems: itemCount, item_array: items }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        window.location.href = data.url;
      })
      .catch((error) => {
        // Handle any errors that may occur during the fetch request
        console.error("Fetch error:", error);
      });
  };


  return (
    <React.Fragment>
      <div className={styles["cart-container"]}>
        <div className={styles["hidden-container"]}>
          <h1 className={styles["cart-header"]}>Shopping Cart</h1>
          <div className={styles["cart-items-container"]}>
            {items.length <= 0 && (
              <h2 className={styles["cart-empty"]}>Cart is empty</h2>
            )}
            {uniqueItems.map(
              (item, index) =>
                uniqueItems.length > 0 && (
                  <div className={styles["cart-item-container"]} key={index}>
                    <div className={styles["cart-item-content"]}>
                      <h2 className={styles["cart-item-title"]}>{item.name} : {item.size}</h2>
                      <h2 className={styles["cart-item-price"]}>
                        {itemCount[`${item.name} ${item.size}`] * item.price}
                      </h2>
                      <div className={styles["cart-item-quantity"]}>
                        <input
                          type="number"
                          min="1"
                          defaultValue={itemCount[`${item.name} ${item.size}`]}
                          onChange={(e) =>
                            handleQuantityChange(
                              item,
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                      </div>
                      <button
                        className={styles["cart-item-remove-button"]}
                        onClick={() => removeFromCart(item.name, item.price_id)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                )
            ) }
          </div>
          {items.length > 0 && (
            <button
              className={styles["cart-checkout-button"]}
              type="button"
              onClick={handleCheckout}
            >
              Checkout
            </button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Cart;
